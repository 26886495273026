import { Box, Grid, Paper, Stack, Typography, useTheme, Button , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Backdrop, CircularProgress, Avatar} from "@mui/material";
import { Helmet } from 'react-helmet';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import axios from 'axios';
import { tokens } from "../../theme";
import { styled } from '@mui/material/styles';
import StackedBar from "../../components/StackedBar";
import db from '../../firebase-config'
import React,{useState,useEffect} from "react"
import {collection,onSnapshot,query,where} from "firebase/firestore"
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import img_Layout from '../../assets/images/Layout_Bakery.PNG'
//import img_thermometer from '../../assets/images/thermometer.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SingleRadialChart from "../../components/SingleRadialChart";
import { apiURL } from "../constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2, 
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  width: '100%',
  borderRadius:'10px',
}));

const Production = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columnHeader = [
    { id: 'sku', label: 'SKU', minWidth: 50, setAlign : 'left'},
    { id: 'yield', label: '% Yield', minWidth: 40, setAlign : 'center'},
    { id: 'std_yield', label: '% STD Yield', minWidth: 40, setAlign : 'center'},
   ]
/*---------------- ดึงข้อมูล - Machine status ----------------*/
  const [statusMachine, setStatusMachine] = useState([])
  useEffect(() => {
      const mcstatusCollectionRef = collection(db,'mes_bakery_last_status')
      const subscriber = onSnapshot(mcstatusCollectionRef, snapshot =>{
          setStatusMachine(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()})))
      })
       //console.log(statusMachine)
      return () => {
          subscriber()
      }
  }, [db])

  const [openMachineStatus, setOpenMachineStatus] = useState(false)
  const handleMachineStatusOpen=()=>{
    setOpenMachineStatus(true);
  }
  const handleMachineStatusClose=()=>{
    setOpenMachineStatus(false);
  }

/*---------------- ดึงข้อมูล - เลข Batch ฝั่ง pack ----------------*/
const [batchID, setBatchID] = useState([])
useEffect(() => {
  const batchIDCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("batch", "!=", ""))
  const subscriber = onSnapshot(batchIDCollectionRef, snapshot =>{
      setBatchID(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()}))) 
  })
      //console.log(batchID)
  return () => {
      subscriber()
  }
}, [batchID])

/*---------------- ดึงข้อมูล - การผลิตสะสม ----------------*/
  const [accumProduct, setAccumProduct] = useState([])
  useEffect(() => {
    const accumProductCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("TYPE", "==", "Accum"))
    const subscriber = onSnapshot(accumProductCollectionRef, snapshot =>{
        setAccumProduct(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()}))) 
    })
        //console.log(accumProduct)
    return () => {
        subscriber()
    }
  }, [accumProduct])

  let factoryDateNow = '' 
  for (let i = 0; i<accumProduct.length; i++){
      if (accumProduct !== []) {
        factoryDateNow = accumProduct[i].data.DATE
      //console.log(factoryDateNow)
      }
  } 
  
  const [showCircular, setShowCircular] = useState(false)
  const [updatePlanStatus, setUpdatePlanStatus] = useState(false)
  const [confirmupdateStatus, setConfirmupdateStatus] = useState(false)
  const [resultMsg, setResultMsg] = useState('')
  const [descMsg, setDescMsg] = useState('')

  /*const openpopup=()=>{
    setUpdatePlanStatus(true);
  }*/
  const closepopup=()=>{
    setUpdatePlanStatus(false);
  }

  //Confirm update plan button (insertion-init-firestore) (click YES)
  const confirmUpdate=()=>{ 
    setConfirmupdateStatus(false)
    if (factoryDateNow !== '') {
      setShowCircular(true)
      checkToken();
        axios.post(apiURL.UpdatePlan_2 + factoryDateNow + '/',{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("rb_ac_token"),
          },
        })
  
        .then(response => {
          console.log('Updating plan in 7 minutes.')
          setResultMsg('Updating production plan')
          setDescMsg('Production plan will be update in 7 minutes.')
          setShowCircular(false)
          setUpdatePlanStatus(true)
        })
        .catch(error => {
          console.error('Error Update plan:', error)
          setResultMsg('Update plan unsuccessful')
          setDescMsg('Cannot update production plan, please try again.')
          setShowCircular(false)
          setUpdatePlanStatus(true)
        });
    } else {
        setResultMsg('Update plan unsuccessful')
        setDescMsg('Cannot update production plan, please try again.')
        setUpdatePlanStatus(true)
    }
  }

  const cancelUpdate=()=>{
    setConfirmupdateStatus(false)
  }

  //Update plan button (insertion-init-firestore)
  const handleClick = () => {
    setConfirmupdateStatus(true)
  };

  /*---------------- ดึงข้อมูล - accumSKU ----------------*/
  const [accumSKU, setAccumSKU] = useState([])
  useEffect(() => { 
      const accumSKUCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("TYPE", "==", "SKU Detail"))
      const subscriber = onSnapshot(accumSKUCollectionRef, snapshot =>{
        setAccumSKU(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()})))
      })
        //console.log(accumSKU)
      return () => {
          subscriber()
      }
  }, [accumSKU])

  /*---------------- ดึงข้อมูล - ลำดับการผลิตสินค้าตามแผน ----------------*/
const [orderSKU, setOrderSKU] = useState([])
useEffect(() => {
  const orderSKUCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("OUTPUT_SAP_CODE", "!=", ""))
  const subscriber = onSnapshot(orderSKUCollectionRef, snapshot =>{
      setOrderSKU(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()}))) 
  })
      //console.log(orderSKU)
  return () => {
      subscriber()
  }
}, [orderSKU])

  /*---------------- ดึงข้อมูล - ประสิทธิภาพการผลิต ----------------*/
  const [producePer, setProducePer] = useState([])
  useEffect(() => { 
      const producePerCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("TYPE", "==", "PERFORMANCE"))
      const subscriber = onSnapshot(producePerCollectionRef, snapshot =>{
        setProducePer(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()})))
      })
        //console.log(producePer)
      return () => {
          subscriber()
      }
  }, [producePer])

  /*---------------- ดึงข้อมูล - SKU-Yield ----------------*/
  const [skuYield, setSkuYield] = useState([])
  useEffect(() => { 
      const skuYieldCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("TYPE", "==", "SKU Yield"))
      const subscriber = onSnapshot(skuYieldCollectionRef, snapshot =>{
        setSkuYield(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()})))
      })
        //console.log(skuYield)
      return () => {
          subscriber()
      }
  }, [skuYield])

  //console.log(localStorage.getItem('isLogin'))
  var token_url = apiURL.TOKEN;
  // check token
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    if (refresh_exp < Date.now() / 1000) {
      console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      //console.log('redirect to login')
      window.location = "/login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");
      //console.log(localStorage.getItem("rb_rf_token"));
      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  /*---------------- ดึงข้อมูลจาก API - OEE ----------------*/
  
  const [oee, setOEE] = useState({A:'' , P:'' , Q:'', OEE:''})

  useEffect(() => {
    async function fetchDataOEE() {
      checkToken();
      var get_OEEdata = apiURL.OEE + factoryDateNow + '/'
      //console.log(factoryDateNow)
      if (factoryDateNow !== '') {
        await axios.get(get_OEEdata,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("rb_ac_token"),
          },
        })
   
        .then((response) => {
          if (response.status === 200) {
          //console.log(response.data)
          setOEE(response.data)}})
        
        .catch((err) => {
           console.log('API OEE error:',err.message);
        }); 
      } 
    }

    fetchDataOEE()

    const interval = setInterval(() => fetchDataOEE(), 1 * 60 * 1000) // Read data every 1 min
    return () => {
      clearInterval(interval);
    }
  },[factoryDateNow])

  let OEEData =[
    {
      'name': 'OEE',
      'value': oee.OEE !== ''? oee.OEE.toFixed(2) : 'NA'
    },
    {
      'name': 'AVA',
      'value': oee.A !== ''? oee.A : 'NA'
    },
    {
      'name': 'PER',
      'value': oee.P !== ''? oee.P: 'NA'
    },
    {
      'name': 'QUA',
      'value': oee.Q !== ''? oee.Q: 'NA'
    },
  ]

function machine_status(statusData) {
  let status_color = ''
  if (statusData.quality === false) {
      status_color = 'lightblue'
  } else if (statusData.run === false && statusData.fault === false && statusData.quality === true) {
      status_color = 'lightblue'
  } else if (statusData.run === true && statusData.fault === false && statusData.quality === true){
      status_color = 'lightgreen'
  } else if (statusData.run === false && statusData.fault === true && statusData.quality === true){
      status_color = 'orange'
  } else if (statusData.run === true && statusData.fault === true && statusData.quality === true) {
      if(statusData.run_timestamp >= statusData.fault_timestamp){
         status_color = 'lightgreen'
      } else{
         status_color = 'orange'
      }
    
  } else{
      status_color = 'black'
  }
  return status_color
}

  /*---------------- นำค่าไปแสดง - Machine status ----------------*/
// ----- Status -----
let chiller1cold_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let chiller2_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let spongemixer_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let semi_ahu1_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let ahu1_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let doughmixer_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let makeup_system_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let doughpump_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let dustcollector_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let ahu2_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let finalproof_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let seeder_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let oven_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let depanner_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let depannerwasher_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let washer_status =  {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let turnOverBake_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let stacker_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let unstacker_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let turnOverMix_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let spiralcooling_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let packing_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let md_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let basketUnstack_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let curtfeeder_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}
let stackfeeder_status = {run :'' , fault :'' , quality :'' , color :'' , run_timestamp :'' , fault_timestamp :''}

/*----------- นำค่าไปแสดง - Machine status 2nd page -----------*/
/* ------- Prep zone -------*/
let prep_zone = { ahu1_room_temp : '',
                  ahu1_room_humidity : '',
                  line_speed : '',
                  chiller_cold_temp : '',
                  prep_zone_temp : ''}
/* ------- Oven zone -------*/
let oven_zone = { ahu2_room_temp : '',
                  ahu2_room_humidity : '',
                  oven_z1_up : '',
                  oven_z2_up : '',
                  oven_z3_up : '',
                  oven_z1_low : '',
                  oven_z2_low : '',
                  oven_z3_low : '',}
/* ------- Packing zone -------*/
let packing_zone = { count_basket : '',
                     count_pack : '',
                     cooling_zone_temp : '',
                     packing_zone_temp : ''}

for (let i = 0; i < statusMachine.length; i++) {
//console.log(statusMachine[i].data.value)
// --------------- Chiller 1 -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_1.preparation_room.Brine_chiller_1_RUN'){
    chiller1cold_status.run = statusMachine[i].data.value
    chiller1cold_status.run_timestamp = statusMachine[i].data.timestamp
    //console.log('chiller1cold_status.run_timestamp = ',chiller1cold_status.run_timestamp)  
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_1.preparation_room.Brine_chiller_1_FAULT'){
    chiller1cold_status.fault = statusMachine[i].data.value
    chiller1cold_status.quality = statusMachine[i].data.q
    chiller1cold_status.fault_timestamp = statusMachine[i].data.timestamp
    //console.log(chiller1cold_status.fault_timestamp)
}

// --------------- Chiller 2 -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_2.preparation_room.Brine_chiller_2_RUN'){
    chiller2_status.run = statusMachine[i].data.value
    chiller2_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_2.preparation_room.Brine_chiller_2_FAULT'){
    chiller2_status.fault = statusMachine[i].data.value
    chiller2_status.quality = statusMachine[i].data.q
    chiller2_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Sponge mixer -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.sponge_mixer_4.preparation_room.sponge_mixer_4_RUN'){
    spongemixer_status.run = statusMachine[i].data.value
    spongemixer_status.run_timestamp = statusMachine[i].data.timestamp 
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.sponge_mixer_4.preparation_room.sponge_mixer_4_FAULT'){
    spongemixer_status.fault = statusMachine[i].data.value
    spongemixer_status.quality = statusMachine[i].data.q
    spongemixer_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Semi AHU 1 -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.semi_ahu_1.preparation_room.semi_AHU1_5_RUN'){
  semi_ahu1_status.run = statusMachine[i].data.value
  semi_ahu1_status.run_timestamp = statusMachine[i].data.timestamp  
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.semi_ahu_1.preparation_room.semi_AHU1_5_FAULT'){
  semi_ahu1_status.fault = statusMachine[i].data.value
  semi_ahu1_status.quality = statusMachine[i].data.q
  semi_ahu1_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- AHU 1 -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_RUN'){
    ahu1_status.run = statusMachine[i].data.value  
    ahu1_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_FAULT'){
    ahu1_status.fault = statusMachine[i].data.value
    ahu1_status.quality = statusMachine[i].data.q
    ahu1_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Dough mixer -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.final_mixer_7.preparation_room.final_mixer_7_RUN'){
    doughmixer_status.run = statusMachine[i].data.value  
    doughmixer_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.final_mixer_7.preparation_room.final_mixer_7_FAULT'){
    doughmixer_status.fault = statusMachine[i].data.value
    doughmixer_status.quality = statusMachine[i].data.q
    doughmixer_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Makeup system -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.makeup_system.makeup_system_10.preparation_room.makeup_system_10_RUN'){
  makeup_system_status.run = statusMachine[i].data.value  
  makeup_system_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.makeup_system.makeup_system_10.preparation_room.makeup_system_10_FAULT'){
  makeup_system_status.fault = statusMachine[i].data.value
  makeup_system_status.quality = statusMachine[i].data.q
  makeup_system_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Dough pump -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.makeup_system.makeup_system_10.preparation_room.dough_pump_10_RUN'){
  doughpump_status.run = statusMachine[i].data.value  
  doughpump_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.makeup_system.makeup_system_10.preparation_room.dough_pump_10_FAULT'){
  doughpump_status.fault = statusMachine[i].data.value
  doughpump_status.quality = statusMachine[i].data.q
  doughpump_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Dust collector -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.dust_collector.dust_colletor_13.preparation_room.dust_colletor_13_fault'){
  dustcollector_status.fault = statusMachine[i].data.value
  dustcollector_status.quality = statusMachine[i].data.q
  dustcollector_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- AHU 2 -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_RUN'){
    ahu2_status.run = statusMachine[i].data.value  
    ahu2_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_FAULT'){
    ahu2_status.fault = statusMachine[i].data.value
    ahu2_status.quality = statusMachine[i].data.q
    ahu2_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Final proof -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.proofer.proofer_15.preparation_room.proofer_15_RUN'){
    finalproof_status.run = statusMachine[i].data.value 
    finalproof_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.proofer.proofer_15.preparation_room.proofer_15_FAULT'){
    finalproof_status.fault = statusMachine[i].data.value
    finalproof_status.quality = statusMachine[i].data.q
    finalproof_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Seeder -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.seeder.seeder_16.preparation_room.seeder_16_RUN'){
  seeder_status.run = statusMachine[i].data.value  
  seeder_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.seeder.seeder_16.preparation_room.seeder_16_FAULT'){
  seeder_status.fault = statusMachine[i].data.value
  seeder_status.quality = statusMachine[i].data.q
  seeder_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Oven -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_RUN'){
    oven_status.run = statusMachine[i].data.value  
    oven_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_FAULT'){
    oven_status.fault = statusMachine[i].data.value
    oven_status.quality = statusMachine[i].data.q
    oven_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Depanner -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.depanner.depanner_21.preparation_room.oven_depanner_21_RUN'){
    depanner_status.run = statusMachine[i].data.value  
    depanner_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.depanner.depanner_21.preparation_room.oven_depanner_21_FAULT'){
    depanner_status.fault = statusMachine[i].data.value
    depanner_status.quality = statusMachine[i].data.q
    depanner_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Depanner washer -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.washer.depanner_washer_22.preparation_room.depanner_washer_22_RUN'){
  depannerwasher_status.run = statusMachine[i].data.value
  depannerwasher_status.run_timestamp = statusMachine[i].data.timestamp 
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.washer.depanner_washer_22.preparation_room.depanner_washer_22_FAULT'){
  depannerwasher_status.fault = statusMachine[i].data.value
  depannerwasher_status.quality = statusMachine[i].data.q
  depannerwasher_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Washer -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.washer.washer_23.preparation_room.washer_23_RUN'){
  washer_status.run = statusMachine[i].data.value  
  washer_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.washer.washer_23.preparation_room.washer_23_FAULT'){
  washer_status.fault = statusMachine[i].data.value
  washer_status.quality = statusMachine[i].data.q
  washer_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Turn over Baking room -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.turn_over.turn_over_24.preparation_room.turn_over_24_1_RUN'){
  turnOverBake_status.run = statusMachine[i].data.value  
  turnOverBake_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.turn_over.turn_over_24.preparation_room.turn_over_24_1_FAULT'){
  turnOverBake_status.fault = statusMachine[i].data.value
  turnOverBake_status.quality = statusMachine[i].data.q
  turnOverBake_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Stacker -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.stacker.stacker_24.preparation_room.stacker_24_RUN'){
  stacker_status.run = statusMachine[i].data.value  
  stacker_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.stacker.stacker_24.preparation_room.stacker_24_FAULT'){
  stacker_status.fault = statusMachine[i].data.value
  stacker_status.quality = statusMachine[i].data.q
  stacker_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Unstacker -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.unstack.unstacker_25.preparation_room.unstacker_25_RUN'){
  unstacker_status.run = statusMachine[i].data.value 
  unstacker_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.unstack.unstacker_25.preparation_room.unstacker_25_FAULT'){
  unstacker_status.fault = statusMachine[i].data.value
  unstacker_status.quality = statusMachine[i].data.q
  unstacker_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Turn over mixing room -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.turn_over.turn_over_25.preparation_room.turn_over_25_1_RUN'){
  turnOverMix_status.run = statusMachine[i].data.value  
  turnOverMix_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.turn_over.turn_over_25.preparation_room.turn_over_25_1_FAULT'){
  turnOverMix_status.fault = statusMachine[i].data.value
  turnOverMix_status.quality = statusMachine[i].data.q
  turnOverMix_status.fault_timestamp = statusMachine[i].data.timestamp
}


// --------------- Spiral cooling -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.spiral_cooler.spiral_cooler_27.packing_room.spiral_cooler_27_RUN'){
    spiralcooling_status.run = statusMachine[i].data.value  
    spiralcooling_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.spiral_cooler.spiral_cooler_27.packing_room.spiral_cooler_27_FAULT'){
    spiralcooling_status.fault = statusMachine[i].data.value
    spiralcooling_status.quality = statusMachine[i].data.q
    spiralcooling_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Packing -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.packing.pack_29.packing_room.packing_system_29_RUN'){
    packing_status.run = statusMachine[i].data.value  
    packing_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.packing.pack_29.packing_room.packing_system_29_FAULT'){
    packing_status.fault = statusMachine[i].data.value
    packing_status.quality = statusMachine[i].data.q
    packing_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Metal detector -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.metal_detector.metal_detector_30.packing_room.metal_detactor_30_RUN'){
    md_status.run = statusMachine[i].data.value  
    md_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.metal_detector.metal_detector_30.packing_room.metal_detactor_30_FAULT'){
    md_status.fault = statusMachine[i].data.value
    md_status.quality = statusMachine[i].data.q
    md_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Basket Unstack -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.unstack.basket_unstack_31.packing_room.basket_unstack_31_RUN'){
  basketUnstack_status.run = statusMachine[i].data.value  
  basketUnstack_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.unstack.basket_unstack_31.packing_room.basket_unstack_31_FAULT'){
  basketUnstack_status.fault = statusMachine[i].data.value
  basketUnstack_status.quality = statusMachine[i].data.q
  basketUnstack_status.fault_timestamp = statusMachine[i].data.timestamp
}
// --------------- Curt feeder -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.curt_feeder_32.packing_room.curt_feeder_32_RUN'){
  curtfeeder_status.run = statusMachine[i].data.value  
  curtfeeder_status.run_timestamp = statusMachine[i].data.timestamp
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.curt_feeder_32.packing_room.curt_feeder_32_FAULT'){
  curtfeeder_status.fault = statusMachine[i].data.value
  curtfeeder_status.quality = statusMachine[i].data.q
  curtfeeder_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Stack feeder -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.stack_feeder_33.packing_room.stack_feeder_33_1_RUN'){
    stackfeeder_status.run = statusMachine[i].data.value 
    stackfeeder_status.run_timestamp = statusMachine[i].data.timestamp 
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.stack_feeder_33.packing_room.stack_feeder_33_1_FAULT'){
    stackfeeder_status.fault = statusMachine[i].data.value
    stackfeeder_status.quality = statusMachine[i].data.q
    stackfeeder_status.fault_timestamp = statusMachine[i].data.timestamp
}

// --------------- Prep zone -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_room_temp'){
  prep_zone.ahu1_room_temp = statusMachine[i].data.value  
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_room_humidity'){
  prep_zone.ahu1_room_humidity = statusMachine[i].data.value  
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.makeup_system.makeup_system_10.preparation_room.makeup_system_10_line_speed'){
  prep_zone.line_speed = statusMachine[i].data.value  
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_1.preparation_room.Brine_chiller_1_cold_temp'){
  prep_zone.chiller_cold_temp = statusMachine[i].data.value  
}
if (statusMachine[i].data.id === 'temp_03'){
  prep_zone.prep_zone_temp = statusMachine[i].data.value  
  //console.log(prep_zone.prep_zone_temp)
}

// --------------- Oven zone -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_room_temp'){
  oven_zone.ahu2_room_temp = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_room_humidity'){
  oven_zone.ahu2_room_humidity = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone1_up'){
  oven_zone.oven_z1_up = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone2_up'){
  oven_zone.oven_z2_up = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone3_up'){
  oven_zone.oven_z3_up = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone1_low'){
  oven_zone.oven_z1_low = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone2_low'){
  oven_zone.oven_z2_low = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_zone3_low'){
  oven_zone.oven_z3_low = statusMachine[i].data.value
}

// --------------- Packing zone -----------------
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.counter.basket_count.packing_room.Count_bask'){
  packing_zone.count_basket = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.packing.pack_29.packing_room.fx_pack'){
  packing_zone.count_pack = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'temp_04'){
  packing_zone.cooling_zone_temp = statusMachine[i].data.value
}
if (statusMachine[i].data.id === 'temp_05'){
  packing_zone.packing_zone_temp = statusMachine[i].data.value 
}


}

//console.log('test','2023-09-16 23:42:41' >= '2023-09-18 16:42:41')

// --------------- Chiller 1 -----------------
chiller1cold_status.color = machine_status(chiller1cold_status)
// --------------- Chiller 2 -----------------
chiller2_status.color = machine_status(chiller2_status)
// --------------- Sponge mixer -----------------
spongemixer_status.color = machine_status(spongemixer_status)
// --------------- Semi AHU 1 -----------------
semi_ahu1_status.color = machine_status(semi_ahu1_status)
// --------------- AHU 1 -----------------
ahu1_status.color = machine_status(ahu1_status)
// --------------- Dough mixer -----------------
doughmixer_status.color = machine_status(doughmixer_status)
// --------------- Makeup system -----------------
makeup_system_status.color = machine_status(makeup_system_status)
// --------------- Dough pump -----------------
doughpump_status.color = machine_status(doughpump_status)
// --------------- Dust collector -----------------
if (dustcollector_status.quality === false) {
  dustcollector_status.color = 'lightblue'
} else if (dustcollector_status.fault === false && dustcollector_status.quality === true) {
  dustcollector_status.color = 'lightblue'
} else if (dustcollector_status.fault === true && dustcollector_status.quality === true){
  dustcollector_status.color = 'orange'
} else {
  dustcollector_status.color = 'black'
} 
// --------------- AHU 2 -----------------
ahu2_status.color = machine_status(ahu2_status)
// --------------- Final proof -----------------
finalproof_status.color = machine_status(finalproof_status)
// --------------- Seeder -----------------
seeder_status.color = machine_status(seeder_status)
// --------------- Oven -----------------
oven_status.color = machine_status(oven_status)
// --------------- Depanner -----------------
depanner_status.color = machine_status(depanner_status)
// --------------- Depanner washer -----------------
depannerwasher_status.color = machine_status(depannerwasher_status)
// --------------- Washer -----------------
washer_status.color = machine_status(washer_status)
// --------------- Turn over Baking room -----------------
turnOverBake_status.color = machine_status(turnOverBake_status)
// --------------- Stacker -----------------
stacker_status.color = machine_status(stacker_status)
// --------------- Unstacker -----------------
unstacker_status.color = machine_status(unstacker_status)
// --------------- Turn over mixing room -----------------
turnOverMix_status.color = machine_status(turnOverMix_status) 
// --------------- Spiral cooling -----------------
spiralcooling_status.color = machine_status(spiralcooling_status)
// --------------- Packing -----------------
packing_status.color = machine_status(packing_status)
// --------------- Metal detector -----------------
md_status.color = machine_status(md_status)
// --------------- Basket unstack -----------------
basketUnstack_status.color = machine_status(basketUnstack_status)
// --------------- Curt feeder -----------------
curtfeeder_status.color = machine_status(curtfeeder_status) 
// --------------- Stacker feeder -----------------
stackfeeder_status.color = machine_status(stackfeeder_status)

//---------------- Production line status -----------
let productionLine_status = 'rgb(14, 234, 186)'
if (chiller1cold_status.color === 'red' || chiller2_status.color === 'red' || spongemixer_status.color === 'red' || semi_ahu1_status.color === 'red' || ahu1_status.color === 'red'
    || doughmixer_status.color === 'red' || makeup_system_status.color === 'red' || doughpump_status.color === 'red' || dustcollector_status.color === 'red' || ahu2_status.color === 'red'
    || finalproof_status.color === 'red' || seeder_status.color === 'red' || oven_status.color === 'red' || depanner_status.color === 'red' || depannerwasher_status.color === 'red' 
    || washer_status.color === 'red' || turnOverBake_status.color === 'red' || stacker_status.color === 'red' || unstacker_status.color === 'red' || turnOverMix_status.color === 'red'
    || spiralcooling_status.color === 'red' || packing_status.color === 'red' || md_status.color === 'red' || basketUnstack_status.color === 'red' || curtfeeder_status.color === 'red'
    || stackfeeder_status.color === 'red') {
    productionLine_status = 'red'
} else { // All of machines not fault
    productionLine_status = 'rgb(14, 234, 186)'
}

  /*---------------- นำค่าไปแสดง- batch ฝั่ง pack ----------------*/ 
  let batch_no = ''
  for (let i = 0; i<batchID.length; i++){
    batch_no = batchID[i].data.batch
  }


  /*---------------- นำค่าไปแสดง-การผลิตสะสม StackedBar ----------------*/
  let accumProductTarget = 0
  let accumProductPerAct = 0
  let OverallStackedBarData = {
    series: [{
      name: 'Actual',
      data: [0]
    }, {
      name: 'Remaining',
      data: [0]
    }
    ],
    category: ['' ]
  }
  for (let i = 0; i<accumProduct.length; i++){
        OverallStackedBarData.series[0].data = [accumProduct[i].data.ACTUAL]
        OverallStackedBarData.series[1].data = [accumProduct[i].data.REMAINING]<0? 0:[accumProduct[i].data.REMAINING]
        accumProductTarget = accumProduct[i].data.TOTAL
        if ((accumProduct[i].data.TOTAL)!== 0){
          accumProductPerAct = (100*(accumProduct[i].data.ACTUAL)/(accumProduct[i].data.TOTAL)).toFixed(2)
        } else{
          accumProductPerAct = 'NA'
        }
        
        //console.log(OverallStackedBarData)
  }

  /*---------------- นำค่าไปแสดง-การผลิตสะสมแต่ละ SKU ----------------*/
  /*
  let StackedBarData = {
    series: [{
      name: 'Actual',
      data: []
    }, {
      name: 'Remaining',
      data: []
    }
    ],
    category: []
  }
  for (let i = 0; i<accumSKU.length; i++){
    for (let j = 0; j<accumSKU.length; j++){
        if (orderSKU[j].data.INDEX === i){
          for (let k = 0; k<accumSKU.length; k++){
            if (orderSKU[j].data.OUTPUT_SAP_CODE === accumSKU[k].data.SAP_CODE) {
              StackedBarData.series[0].data.push(accumSKU[k].data.ACTUAL)
              StackedBarData.series[1].data.push(accumSKU[k].data.REMAINING)
              //StackedBarData.category.push("SKU-" + accumSKU[k].data.SAP_CODE + "  Target : " +accumSKU[k].data.TARGET)
              StackedBarData.category.push("SKU-" + accumSKU[k].data.SAP_CODE)
            }
          }
        }
    }
  }
  */
   //console.log('Test',accumSKU)
   let SKU_SAP_CODE = ['23064440','23083021','23064443','23083020','23066761','23071968','23071966','23091649','23103723','23102905','23100447','23105293']
   let SKU_MES_NAME = ['Hotdog 45g โรยงา','Hotdog 45g โรยงา (RTE)','Burger 45g โรยงา','Burger 45g โรยงา (RTE)','Burger 50g โรยงา','Burger 50g ไม่โรยงา','Hotdog 35g แช่แข็ง','Hotdog 30g แช่แข็ง','Burger Garlic 50g','Hotdog 40g โรยงา','Hotdog 50g ไม่โรยงา','Burger 45g ไม่โรยงา']
   let MES_NAME = ''
   let Remain_bun = 0
   /*---------------- นำค่าไปแสดง-การผลิตสะสมแต่ละ SKU ----------------*/
    let StackedBarDataNew = []
    for (let i = 0; i<orderSKU.length; i++){
       let check_duplicate_StackedBarData = false
      for (let j = 0; j<orderSKU.length; j++){
          if (orderSKU[j].data.INDEX === i){
            for (let k = 0; k<accumSKU.length; k++){
              if (orderSKU[j].data.OUTPUT_SAP_CODE === accumSKU[k].data.SAP_CODE) {
                for (let l = 0; l<StackedBarDataNew.length; l++){
                  if (StackedBarDataNew[l].category[0] === "SKU-"+ accumSKU[k].data.SAP_CODE){
                    check_duplicate_StackedBarData = true
                  }
                }
                if (check_duplicate_StackedBarData === false){
                  let check_matched_SAPCode = false
                  for(let m = 0; m<12; m++){
                    if(accumSKU[k].data.SAP_CODE == SKU_SAP_CODE[m]){
                        check_matched_SAPCode = true
                        MES_NAME = SKU_MES_NAME[m]
                    }
                  }
                  if(check_matched_SAPCode == false){
                    MES_NAME = orderSKU[j].data.SKU
                  }
                  if(accumSKU[k].data.REMAINING < 0){
                     Remain_bun = 0
                  } else{
                     Remain_bun = accumSKU[k].data.REMAINING
                  }
                  
                  StackedBarDataNew.push({
                    series: [{
                      name: 'Actual',
                      data: [accumSKU[k].data.ACTUAL],
                    }, {
                      name: 'Remaining',
                      data: [Remain_bun]
                    }
                    ],
                    category: ["SKU-" + accumSKU[k].data.SAP_CODE],
                    target: [accumSKU[k].data.TARGET],
                    production_status : [orderSKU[j].data.STATUS],
                    MasterSKU : [orderSKU[j].data.SKU],
                    MES_name : [MES_NAME]
                    
                  })
                  MES_NAME = ''
                }
              }
            }
          }
      }
    }
    //console.log(StackedBarDataNew)
    /*---------------- นำค่าไปแสดง - ประสิทธิภาพการผลิต ----------------*/
    let PerformanceData = {
      pcsperhour: {
        actual: '',
        std: ''
      },
      pcsperpersonhour: {
        actual: '',
        std: '',
        stdEmployee: ''
      }
    } 
    let pcsperhourStdWithComma = ''
    let pcsperhourStdWithoutComma = ''
    for (let i = 0; i<producePer.length; i++){
      pcsperhourStdWithComma = producePer[i].data.STD_PIECE_PER_HR.substring(0, 6)
      pcsperhourStdWithoutComma = parseInt(pcsperhourStdWithComma.replace(/,/g, ''), 10);
      PerformanceData.pcsperhour.actual = producePer[i].data.ACTUAL_PIECE_PER_HR
      PerformanceData.pcsperhour.std = producePer[i].data.STD_PIECE_PER_HR.substring(0, 6)
      PerformanceData.pcsperpersonhour.actual = producePer[i].data.ACTUAL_PIECE_PER_PERSON_HR
      PerformanceData.pcsperpersonhour.std = producePer[i].data.STD_PIECE_PER_PERSON_HR
      PerformanceData.pcsperpersonhour.stdEmployee = producePer[i].data.STD_EMPLOYEE
  }
    //console.log(PerformanceData)

    /*---------------- นำค่าไปแสดง - Yield แต่ละ SKU ----------------*/
  //let SKUYieldData = []
  //for (let i = 0; i<skuYield.length; i++){
        //SKUYieldData.push({
          //'sku': skuYield[i].data.SAP_CODE,
          //'yield': skuYield[i].data.YIELD,
          //'std_yield': skuYield[i].data.STD_YIELD
        //})
    //}
  
    let SKUYieldDataNew = []
    for (let i = 0; i<orderSKU.length; i++){
      let check_duplicate_SKUYieldData = false
      for (let j = 0; j<orderSKU.length; j++){
          if (orderSKU[j].data.INDEX === i){
            for (let k = 0; k<skuYield.length; k++){
              if (orderSKU[j].data.OUTPUT_SAP_CODE === skuYield[k].data.SAP_CODE) {
                for (let l = 0; l<SKUYieldDataNew.length; l++){
                  if (SKUYieldDataNew[l].sap_code === skuYield[k].data.SAP_CODE){
                    check_duplicate_SKUYieldData = true
                  }
                }
                if(check_duplicate_SKUYieldData === false){
                  let check_matched_SAPCode = false
                  for(let m = 0; m<12; m++){
                    if(skuYield[k].data.SAP_CODE == SKU_SAP_CODE[m]){
                        check_matched_SAPCode = true
                        MES_NAME = SKU_MES_NAME[m]
                    }
                  }
                  if(check_matched_SAPCode == false){
                    MES_NAME = orderSKU[j].data.SKU
                  }
                  SKUYieldDataNew.push({
                    'sku': MES_NAME,
                    'yield': skuYield[k].data.YIELD,
                    'std_yield': skuYield[k].data.STD_YIELD,
                    'sap_code': skuYield[k].data.SAP_CODE,
                    'production_status' : orderSKU[j].data.STATUS,
                  })
                  MES_NAME = ''
                }
              }
            }
          }
      }
    }
    //console.log(SKUYieldData)
  let avgYield = {'avg' : '' ,'std' : '' }
  let eachStdYield = 0
  let eachYield = 0
  let checkYieldData = false
  let skuCompleted = 0
  //console.log(checkYieldData)
  for (let i = 0; i<SKUYieldDataNew.length; i++){
    if (SKUYieldDataNew[i].std_yield !== '' && SKUYieldDataNew[i].std_yield !== 'NA' && SKUYieldDataNew[i].production_status =='Completed'){
      eachStdYield = eachStdYield + SKUYieldDataNew[i].std_yield
      skuCompleted = skuCompleted + 1
    }
    if (SKUYieldDataNew[i].yield !== '' && SKUYieldDataNew[i].yield !== 'NA' && SKUYieldDataNew[i].production_status =='Completed'){
      eachYield = eachYield + SKUYieldDataNew[i].yield
      checkYieldData = true
    }
  }
  if (SKUYieldDataNew !== []) {
    if(skuCompleted == 0){
      avgYield.std = 'NA'
      avgYield.avg = 'NA'
    }
    if(skuCompleted > 0){
      avgYield.std = Math.round(eachStdYield/skuCompleted)
      avgYield.avg = Math.round(eachYield/skuCompleted) 
    }
                
    //console.log(avgYield.std)
  }   

  return (
    <div>
      <Helmet>
        <title>Production | MES Bakery</title>
      </Helmet>
      <Box padding = '4px'>
        {/* PAGE HEADER */}
            <Box 
                sx={{
                    height: '30px',
                    backgroundColor: productionLine_status ,
                    borderRadius: '5px',
                    marginBottom : "5px"
                }}>
                <Typography color = '#FFF' fontSize='18px' fontWeight='bold' paddingLeft='18px' paddingTop='2px'>การผลิต : ไลน์ขนมปัง</Typography>
            </Box>
            {/* BODY */}
            <Grid container spacing={1}>
                <Grid item xs={6} >
                    <Item>
                      <Box 
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                      >
                        {/* SECTION 1 */}
                        {/* HEADER */}
                        <Box
                          gridColumn="span 12"
                        //   backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="left"
                          justifyContent="left"
                        >
                          <Typography
                              fontSize='14px'
                              fontWeight="600"
                              marginLeft="16px"
                              color={colors.grey[100]}
                            >
                              ผลผลิตสะสม&nbsp;&nbsp;&nbsp;&nbsp;
                          </Typography> 
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Click to update production plan</Typography>} arrow placement="right">
                              <Button style={{backgroundColor:'#09a08c', height:'26px' , fontSize:'12px'}} variant="contained" onClick={handleClick} >Update plan</Button>
                              {/*<Button style={{backgroundColor:'#09a08c'}} variant="contained" onClick={openpopup} >Test popup</Button>*/}
                          </Tooltip>

                          <Backdrop
                              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                              open={showCircular}
                          >
                                <CircularProgress color="inherit" />
                          </Backdrop>

                          <Dialog
                              open={updatePlanStatus}
                              onClose={closepopup}
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title" fontSize='24px'>
                                  {resultMsg}
                              </DialogTitle>
                              <DialogContent>
                                  <DialogContentText id="alert-dialog-description" fontSize='20px'>
                                      {descMsg}
                                      {/*Update plan completed*/}
                                  </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                  <Button style={{backgroundColor:'#09a08c'}} onClick={closepopup} autoFocus variant="contained">OK</Button>
                              </DialogActions>
                          </Dialog>

                          <Dialog
                              open={confirmupdateStatus}
                              onClose={cancelUpdate}
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title" fontSize='24px'>
                                  {'Confirm to update production plan'}
                              </DialogTitle>
                              <DialogContent>
                                  <DialogContentText id="alert-dialog-description" fontSize='20px'>
                                      {'Do you want to update production plan?'}
                                      {/*Update plan completed*/}
                                  </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                  <Button style={{backgroundColor:'#09a08c'}} onClick={confirmUpdate} autoFocus variant="contained">YES</Button>
                                  <Button style={{backgroundColor:'red'}} onClick={cancelUpdate} autoFocus variant="contained">NO</Button>
                              </DialogActions>
                          </Dialog>

                        </Box>
                        {/* ACTUAL & TARGET FOR ACCUMULATIVE PRODUCTION */}
                        <Box 
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="right"
                            justifyContent="right"
                            mt='-12px'
                            mr='32px'
                            >
                            <Typography color="black" fontSize={'14px'} align='right'>
                              Actual &nbsp; {accumProductPerAct} % &nbsp;&nbsp; Target &nbsp; {accumProductTarget.toLocaleString()} ชิ้น
                            </Typography>
                        </Box>
                        {/* ACCUMULATIVE PRODUCTION */}
                        <Box
                          gridColumn="span 12"
                          // backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt='-25px'
                          ml="-5px"
                        >
                          
                          <StackedBar
                              textColor={theme.palette.mode==='dark'? '#fff': colors.primary[500]}
                              backgroundColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                              borderColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                              data= {OverallStackedBarData}
                              height={88}
                              width={560}
                              isLegend={true}
                              isXaxisLabeled={false}
                              alignItems='center'
                              isXaxisTicks={false}
                              targetTotal = {false}
                              datalabel_fontsize = '12px'
                              marginleft = '-10px'
                          />
                          
                        </Box>
                        
                        {/* PRODUCTION DETAIL */}
                        <Box 
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt='-38px'
                            ml='-35px'
                            //height='230px'
                            //border= 'solid 3px #058473'
                            >
                          <table>
                            <tbody>
                              <tr>
                                <td align="center" style={{ fontSize: 11, fontWeight: 'bold', borderBottom: 'none'}}>
                                  <Box sx={{
                                          justifyContent: 'center', mr:'-75px'}}>
                                      <Typography fontSize='13px' color='black'>Status</Typography>
                                  </Box>
                                </td>
                                <td align="center" style={{width:'220px', fontSize: 11, fontWeight: 'bold', borderBottom: 'none'}}><Typography fontSize='13px' mr='-22px'  color='black'>SKU</Typography></td>
                                <td align="center" style={{ fontSize: 11, fontWeight: 'bold', borderBottom: 'none'}}><Typography></Typography></td>
                                <td align="right" style={{width:'13%',fontSize: 11, fontWeight: 'bold', borderBottom: 'none'}}><Typography fontSize='12.8px'  color='black' mr='9px'>Target/day</Typography></td>
                              </tr>
                              {
                                StackedBarDataNew.map((graph) => (
                                <tr>

                                  <Tooltip title= {<Typography color="inherit" fontSize="14px">{graph.production_status =='Packing'? 'Packing' : graph.production_status =='Completed'? 'Completed' : 'Not start'}</Typography>} arrow placement="left">
                                  <td align="center" style={{ width:'10%',fontSize: 14, fontWeight: 'bold', borderBottom: 'none' }}>
                                    <Tooltip title= {<Typography color="inherit" fontSize="14px">{graph.MasterSKU}</Typography>} arrow placement="right">
                                      <Box sx={{
                                          justifyContent: 'center', mr:'-75px'}}>
                                      <CircleRoundedIcon style={{fontSize:'16px', color: graph.production_status =='Packing'? '#00FA9A': graph.production_status =='Completed'? '#09a08c' : 'lightgrey'}}>Production status</CircleRoundedIcon>
                                      </Box>
                                    </Tooltip>
                                  </td>
                                  </Tooltip>

                                  {/*<Tooltip title= {'test'} arrow placement="left">
                                  <td align="center" style={{ width:'50%',fontSize: 14, fontWeight: 'lighter',  borderBottom: 'none', height: "1px" }}>{graph.category}
                                  </td>
                                </Tooltip> */}  
                                  
                                  <td align="left" style={{width:'25%', fontSize: 10, borderBottom: 'none'}}>
                                    <Box sx={{
                                          justifyContent: 'center', pl:'30px'}}>
                                        <Typography fontSize='11px'  color='black'>{graph.MES_name}</Typography>
                                    </Box>
                                    </td>
                                  <td align="center" style={{ width:'46%',fontSize: 14, fontWeight: 'bold', borderBottom: 'none'}}>
                                    <StackedBar
                                      textColor={theme.palette.mode==='dark'? '#fff': colors.primary[500]}
                                      backgroundColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                      borderColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                      data={graph}
                                      height={65}
                                      width={350}
                                      isLegend={false}
                                      isXaxisLabeled={false}
                                      is_accumulative={false}
                                      alignItems='center'
                                      isXaxisTicks={false}
                                      targetTotal = {false}
                                      graphHigh = {15}
                                      datalabel_fontsize = '10px'
                                      marginright = '-22px'
                                      paddingleft = '-100px'
                                      marginleft = '-25px'
                                    />

                                  </td>
                                  
                                  <td align="right" style={{ width:'7%',fontSize: 11, fontWeight: 'bold', borderBottom: 'none'}}>
                                  <Box sx={{
                                          justifyContent: 'center' , mr:'-5px'}}>
                                        <Typography fontSize='11px'  color='black' mr='28px'>{graph.target.toLocaleString()}</Typography>
                                    </Box>
                                  </td>
                                </tr>
                                  
                                ))
                              
                              }
                              </tbody>
                              </table>
                              {/*<StackedBar
                                textColor={theme.palette.mode==='dark'? '#fff': colors.primary[500]}
                                backgroundColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                borderColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                data={StackedBarData}
                                height={250}
                                width={600}
                                isLegend={false}
                                isXaxisLabeled={false}
                                is_accumulative={false}
                                alignItems='center'
                                isXaxisTicks={false}
                              />
                            */}
                        </Box>
                      </Box>
                        
                      {/* SECTION 2 */}
                      <Box 
                        gridColumn="span 12"
                        // backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        //border='solid 3px red'
                        sx={{mt: SKUYieldDataNew.length <= 5? '30px' : SKUYieldDataNew.length == 6? '16px' : '4px'}}
                        
                      >
                          <table>
                            <tbody>
                              <tr>
                                <td align="left" style={{width:'220px', fontWeight: 'bold', height: "1px", color:'black'}}><Typography fontSize= '14px' fontWeight= 'bold' ml='16px'>ประสิทธิภาพการผลิต</Typography></td>
                                <td align="center" style={{width:'150px', fontSize: 13, fontWeight: 'bold', height: "1px", color:'black'}}>Actual</td>
                                <td align="center" style={{width:'150px', fontSize: 13, fontWeight: 'bold', height: "1px", color:'black'}}>Standard</td>
                                <td align="left" style={{width:'150px', fontSize: 13, fontWeight: 'bold', height: "1px", color:'black'}}>Unit</td>
                              </tr>
                              <tr>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}></td>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}><Typography bgcolor='lightgrey' fontSize='13px' color='black'>{PerformanceData.pcsperhour.actual !== '' ? PerformanceData.pcsperhour.actual.toLocaleString() : 'NA'}</Typography></td>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}><Typography fontSize='13px' color='black'>{PerformanceData.pcsperhour.std !== '' ? PerformanceData.pcsperhour.std : 'NA'}</Typography></td>
                                <td align="left" style={{ fontSize: 12, fontWeight: 'bold', height: "1px", color:'black'}}>ชิ้น/ชม.</td>
                              </tr>
                              <tr>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}></td>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}><Typography bgcolor='lightgrey' fontSize='13px' color='black'>{PerformanceData.pcsperpersonhour.actual !== '' ? PerformanceData.pcsperpersonhour.actual.toLocaleString() : 'NA'}</Typography></td>
                                <td align="center" style={{  fontWeight: 'bold', height: "1px"}}><Typography fontSize='13px' color='black'>{PerformanceData.pcsperpersonhour.stdEmployee !== '' && PerformanceData.pcsperpersonhour.stdEmployee !== 0 ? Math.round(pcsperhourStdWithoutComma/PerformanceData.pcsperpersonhour.stdEmployee).toLocaleString() : 'NA'}</Typography></td>
                                <td align="left" style={{ fontSize: 12, fontWeight: 'bold', height: "1px", color:'black'}}>ชิ้น/คน/ชม.</td>
                              </tr>
                            </tbody>
                          </table>  

                      </Box>
                      {/* SECTION 3 */}
                      <Box 
                          gridColumn="span 12"
                          // backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr='10px'
                          mt='0px'
                        >
                          <Grid container>
                          {/* YIELD */}
                            <Grid item xs={4}>
                              <Stack sx={{mt: SKUYieldDataNew.length >= 11? '6px': SKUYieldDataNew.length == 10? '20px' : SKUYieldDataNew.length == 9? '30px': SKUYieldDataNew.length ==8? '40px': SKUYieldDataNew.length ==7? '56px' : SKUYieldDataNew.length ==6? '60px' : SKUYieldDataNew.length ==5? '60px' : '70px'}} ml='20px' mr='30px' display='flex' alignItems='center'>
                                {/* ACTUAL YIELD */}
                                  <Typography  textAlign='center' fontWeight={'bold'} fontSize='16px' color='black'>Yield เฉลี่ย</Typography>
                                  <Box sx={{
                                      marginTop:'20px',
                                      height: '60px',
                                      width:'90px',
                                      backgroundColor: 'lightgrey',
                                      borderRadius: '8px',
                                      display: 'flex',
                                      alignItems:'center',
                                      justifyContent: 'center',                   
                                          }}>
                                      <Typography color='black' fontWeight='bold' fontSize='20px'>{checkYieldData !== false ? avgYield.avg : 'NA' } %</Typography>
                                  </Box>
                                  {/* STD YIELD */}
                                  <Typography marginTop='20px' fontSize='16px'>(std. {avgYield.std !== '' ? avgYield.std : 'NA' } %)</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={8} sx={{mt: SKUYieldDataNew.length == 1? '105px': SKUYieldDataNew.length == 2? '90px': SKUYieldDataNew.length == 3? '75px': SKUYieldDataNew.length == 4? '60px': SKUYieldDataNew.length == 5? '45px': SKUYieldDataNew.length == 6? '30px': '15px'}}  ml='-10px' alignItems='center'>
                            <Paper sx={{ width: '97%', overflow: 'hidden' }}>
                              <TableContainer  sx={{
                               
                                maxHeight: SKUYieldDataNew.length <= 6? 220 : SKUYieldDataNew.length == 7? 240 : SKUYieldDataNew.length == 8? 212 : SKUYieldDataNew.length == 9? 189 : SKUYieldDataNew.length == 10? 163 : 138 ,
                                overflowY: 'scroll'
                                }}
                              >
                                <Table size="small" stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {columnHeader.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align={'center'}
                                          style={{ minWidth: column.minWidth, fontSize:'14px'}}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {SKUYieldDataNew
                                      .map((row) => {
                                        return (
                                          <TableRow hover role="checkbox" tabIndex={-1} key={row.sku}>
                                            {columnHeader.map((column) => {
                                              const value = row[column.id];
                                              return (
                                                <TableCell key={column.id} align={column.setAlign} style={{fontSize:'12px'}}>
                                                    {value}
                                                </TableCell>
                                                
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                            </Grid>
                          </Grid>
                        </Box>
                      
                    </Item>
                </Grid>
                <Grid item xs={6} >
                    <Item>
                      <Box 
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        marginTop="3px"
                      >
                        
                          {
                            OEEData.map((item) =>(
                              <Box
                                gridColumn="span 3"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                                mt={-3}
                                ml='-2px'
                                sx={{mr: item.name==='OEE'? '12px' : '0px'}}
                              >
                                <SingleRadialChart
                                  key={item.name}
                                  value={item.value}
                                  title={item.name}
                                  width={item.name==='OEE'?'120%':'100%'}
                                  height={item.name==='OEE'?'120%':'100%'}
                                />
                              </Box>
                            ))
                            
                          }
                        {/* Machine status */}
                      </Box>
                      <Box mt={-4} border='1px solid lightgrey' height='482px' borderRadius='10px' >
                        <Box sx={{
                           display:'flex',
                           flexDirection :'row',
                           height:'38px',
                           alignItems:'center',
                           //border: 'solid 3px #058473',
                           //justifyContent: 'center'
                              }}> 
                            <Tooltip title={<Typography color="inherit" fontSize="16px">Click to see more detail of machine status</Typography>} arrow placement="right">    
                                <Button style={{backgroundColor:'#09a08c', width:'130px', height:'30px', marginLeft:'10px', fontSize:'12px'}} variant="contained" onClick={handleMachineStatusOpen} >Machine status</Button>
                            </Tooltip> 
                            <CircleRoundedIcon style={{fontSize:'20px', color:'lightblue', marginLeft:'30px'}}>Off/Disconnect status</CircleRoundedIcon>
                            <Typography marginLeft='4px' fontWeight='bold' fontSize='16px' >Off/Disconnect</Typography>
                            <CircleRoundedIcon style={{fontSize:'20px', color:'lightgreen', marginLeft:'15px'}}>Running status</CircleRoundedIcon>
                            <Typography marginLeft='4px' fontWeight='bold' fontSize='16px'>Running</Typography>
                            <CircleRoundedIcon style={{fontSize:'20px', color:'orange', marginLeft:'15px'}}>Warning status</CircleRoundedIcon>
                            <Typography marginLeft='4px' fontWeight='bold' fontSize='16px'>Warning</Typography>
                            <CircleRoundedIcon style={{fontSize:'20px', color:'red', marginLeft:'15px'}}>Fault status</CircleRoundedIcon>
                            <Typography marginLeft='4px' fontWeight='bold' fontSize='16px'>Fault</Typography>
                            
                            {/*<Typography mt='5px' mb='2px' ml='30px' textAlign='left' fontWeight='bold' fontSize='20px'>Stand by &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Running &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fault &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Off/Disconnect</Typography>*/}
                            <Dialog
                              maxWidth='lg'
                              open={openMachineStatus}
                              onClose={handleMachineStatusClose}
                              aria-describedby="alert-dialog-description"
                            >
                              <Box sx={{
                                  display:'flex',
                                  flexDirection:'column',
                                  height:'900px',
                                  width:'1100px',
                                  alignItems:'center',
                                  //border: 'solid 2px #058473',
                                  justifyContent: 'center'
                                      }}>
                                  <Box sx={{
                                      display:'flex',
                                      height:'35px',
                                      width:'1100px',
                                      //border: 'solid 3px red',
                                      justifyContent: 'flex-end',
                                      marginTop:'0px',
                                      marginBottom:'-40px',
                                      paddingRight:'2.9px'
                                          }}>
                                      <Button style={{backgroundColor:'red', fontSize:'16px'}} onClick={handleMachineStatusClose} autoFocus variant="contained">Close</Button>      
                                            </Box>       
                                  <Box sx={{
                                      display:'flex',
                                      flexDirection :'column',
                                      height:'655px',
                                      width:'1100px',
                                      alignItems:'center',
                                      //border: 'solid 3px pink',
                                      justifyContent: 'center',
                                      
                                          }}>
                                      <Box sx={{
                                          display:'flex',
                                          //flexDirection :'row',
                                          height:'35px',
                                          width:'1100px',
                                          alignItems:'center',
                                          //border: 'solid 3px yellow',
                                          justifyContent: 'flex-start',
                                          paddingTop:'10px'
                                              }}>
                                          <Typography marginLeft='20px' fontWeight='bold' fontSize='24px' color='#058473'>Machine status</Typography>
                                          <CircleRoundedIcon style={{fontSize:'20px', color:'lightblue', marginLeft:'30px'}}>Off/Disconnect status</CircleRoundedIcon>
                                          <Typography marginLeft='6px' fontWeight='bold' fontSize='24px' >Off/Disconnect</Typography>
                                          <CircleRoundedIcon style={{fontSize:'20px', color:'lightgreen', marginLeft:'20px'}}>Running status</CircleRoundedIcon>
                                          <Typography marginLeft='6px' fontWeight='bold' fontSize='24px'>Running</Typography>
                                          <CircleRoundedIcon style={{fontSize:'20px', color:'orange', marginLeft:'20px'}}>Warning status</CircleRoundedIcon>
                                          <Typography marginLeft='6px' fontWeight='bold' fontSize='24px'>Warning</Typography>
                                          <CircleRoundedIcon style={{fontSize:'20px', color:'red', marginLeft:'20px'}}>Fault status</CircleRoundedIcon>
                                          <Typography marginLeft='6px' fontWeight='bold' fontSize='24px'>Fault</Typography>
                                          <Typography marginLeft='105px' fontWeight='bold' fontSize='24px'>Batch no :</Typography>
                                          <Typography marginLeft='4px' fontWeight='bold' fontSize='24px' color='#058473'>{batchID !==[] ? batch_no : 'NA'}</Typography>
                                      </Box>
                                      
                                      <Box sx={{
                                          display:'flex',
                                          //flexDirection :'column',
                                          height:'620px',
                                          width:'1100px',
                                          alignItems:'center',
                                          //border: 'solid 3px blue',
                                          justifyContent: 'center'
                                              }}>
                                          <img src ={img_Layout} alt="" width ="786px" height="590px"></img>
                                          {/* จุด Machine status */}
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Chiller 1 cold</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'837px', top:'70px', fontSize:'20px', color: chiller1cold_status.color}}>Chiller 1 cold</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Chiller 2</Typography>} arrow placement="right">
                                            <CircleRoundedIcon style={{position : 'absolute', left:'815px', top:'90px', fontSize:'20px', color: chiller2_status.color}}>Chiller 2</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Sponge mixer</Typography>} arrow placement="right"> 
                                            <CircleRoundedIcon style={{position : 'absolute', left:'293px', top:'145px', fontSize:'20px', color: spongemixer_status.color}}>Sponge mixer</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Semi AHU1</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left: '228px', top:'77px', fontSize:'20px', color: semi_ahu1_status.color}}>Semi AHU1</CircleRoundedIcon>
                                            </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">AHU1</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'194px', top:'105px', fontSize:'20px', color: ahu1_status.color}}>AHU1</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dough mixer</Typography>} arrow placement="right"> 
                                            <CircleRoundedIcon style={{position : 'absolute', left:'218px', top:'273px', fontSize:'20px', color: doughmixer_status.color}}>Dough mixer</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Makeup system</Typography>} arrow placement="right"> 
                                            <CircleRoundedIcon style={{position : 'absolute', left:'190px', top:'295px', fontSize:'20px', color: makeup_system_status.color}}>Makeup system</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dough pump</Typography>} arrow placement="right"> 
                                            <CircleRoundedIcon style={{position : 'absolute', left:'260px', top:'370px', fontSize:'20px', color: doughpump_status.color}}>Dough pump</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dust collector</Typography>} arrow placement="right">
                                            <CircleRoundedIcon style={{position : 'absolute', left:'394px', top:'283px', fontSize:'20px', color: dustcollector_status.color}}>Dust collector</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">AHU2</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'498px', top:'280px', fontSize:'20px', color: ahu2_status.color}}>AHU2</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Final proof</Typography>} arrow placement="right">
                                            <CircleRoundedIcon style={{position : 'absolute', left:'545px', top:'245px', fontSize:'20px', color: finalproof_status.color}}>Final proof</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Seeder</Typography>} arrow placement="right"> 
                                            <CircleRoundedIcon style={{position : 'absolute', left:'508px', top:'368px', fontSize:'20px', color: seeder_status.color}}>Seeder</CircleRoundedIcon>
                                          </Tooltip>   
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Oven</Typography>} arrow placement="right">
                                            <CircleRoundedIcon style={{position : 'absolute', left:'370px', top:'523px', fontSize:'20px', color: oven_status.color}}>Oven</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Depanner</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'642px', top:'228px', fontSize:'20px', color: depanner_status.color}}>Depanner</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Depanner washer</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'650px', top:'125px', fontSize:'20px', color: depannerwasher_status.color}}>Depanner washer</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Washer</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'623px', top:'80px', fontSize:'20px', color: washer_status.color}}>Washer</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Turn over baking room</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'520px', top:'60px', fontSize:'20px', color: turnOverBake_status.color}}>Turn over baking room</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Stacker</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'426px', top:'62px', fontSize:'20px', color: stacker_status.color}}>Stacker</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Unstacker</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'396px', top:'84px', fontSize:'20px', color: unstacker_status.color}}>Unstacker</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Turn over mixing room</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'365px', top:'103px', fontSize:'20px', color: turnOverMix_status.color}}>Turn over mixing room</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Spiral cooling</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'805px', top:'315px', fontSize:'20px', color: spiralcooling_status.color}}>Spiral cooling</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Packing</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'620px', top:'535px', fontSize:'20px', color: packing_status.color}}>Packing</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Metal detector</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'655px', top:'580px', fontSize:'20px', color: md_status.color}}>MD</CircleRoundedIcon>
                                          </Tooltip>
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Basket unstack</Typography>} arrow placement="right">     
                                            <CircleRoundedIcon style={{position : 'absolute', left:'912px', top:'535px', fontSize:'20px', color: basketUnstack_status.color}}>Basket unstack</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Curt feeder</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'818px', top:'523px', fontSize:'20px', color: curtfeeder_status.color}}>Curt feeder</CircleRoundedIcon>
                                          </Tooltip> 
                                          <Tooltip title={<Typography color="inherit" fontSize="16px">Stack feeder</Typography>} arrow placement="right">  
                                            <CircleRoundedIcon style={{position : 'absolute', left:'854px', top:'533px', fontSize:'20px', color: stackfeeder_status.color}}>Stack feeder 33</CircleRoundedIcon>
                                          </Tooltip> 

                                          <Button style={{position : 'absolute', left:'16px', top:'400px', backgroundColor:'#FFFACD', fontSize:'16px', color:'black'}} autoFocus variant="contained" startIcon={<DeviceThermostatOutlinedIcon/>}>
                                            <Typography fontSize='16px'>Prep zone :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='20px' color='#058473'>{prep_zone.prep_zone_temp}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>    
                                          <Button style={{position : 'absolute', left:'850px', top:'280px', backgroundColor:'#FFDAB9', fontSize:'16px', color:'black'}} autoFocus variant="contained" startIcon={<DeviceThermostatOutlinedIcon/>}>
                                            <Typography fontSize='16px'>Cooling zone :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='20px' color='#058473'>{packing_zone.cooling_zone_temp}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>   
                                          <Button style={{position : 'absolute', left:'850px', top:'430px', backgroundColor:'#FFDAB9', fontSize:'16px', color:'black'}} autoFocus variant="contained" startIcon={<DeviceThermostatOutlinedIcon/>}>
                                            <Typography fontSize='16px'>Packing zone :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='20px' color='#058473'>{packing_zone.packing_zone_temp}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>  

                                        {/* Parameter ต่าง ๆ */}  
                                          {/* Temp ห้องหมัก */}
                                          <Button style={{position : 'absolute', left:'183px', top:'58px', backgroundColor:'#FFFACD', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>T :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{prep_zone.ahu1_room_temp}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Humidity ห้องหมัก */}
                                          <Button style={{position : 'absolute', left:'165px', top:'134px', backgroundColor:'#FFFACD', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>H :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{prep_zone.ahu1_room_humidity}</Typography>
                                            <Typography fontSize='16px'>&nbsp;%RH</Typography>
                                          </Button>
                                          {/* Speed การตัดแบ่ง */}
                                          <Button style={{position : 'absolute', left:'240px', top:'320px', backgroundColor:'#FFFACD', fontSize:'16px', color:'black', height:'20px'}}>
                                            <Typography fontSize='18px' color='#058473'>{prep_zone.line_speed.toLocaleString()}</Typography>
                                            <Typography fontSize='16px'>&nbsp;pcs/hr</Typography>
                                          </Button>


                                          {/* Temp Finalproof */}
                                          <Button style={{position : 'absolute', left:'540px', top:'223px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>T :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.ahu2_room_temp}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Humidity Final proof */}
                                          <Button style={{position : 'absolute', left:'480px', top:'310px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>H :&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.ahu2_room_humidity}</Typography>
                                            <Typography fontSize='16px'>&nbsp;%RH</Typography>
                                          </Button>
                                          {/* Zone 1 up */}
                                          <Button style={{position : 'absolute', left:'220px', top:'540px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z1 up&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z1_up}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Zone 2 up */}
                                          <Button style={{position : 'absolute', left:'265px', top:'500px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z2 up&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z2_up}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Zone 3 up */}
                                          <Button style={{position : 'absolute', left:'320px', top:'460px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z3 up&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z3_up}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Zone 1 low */}
                                          <Button style={{position : 'absolute', left:'345px', top:'570px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z1 low&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z1_low}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Zone 2 low */}
                                          <Button style={{position : 'absolute', left:'400px', top:'530px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z2 low&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z2_low}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>
                                          {/* Zone 3 low */}
                                          <Button style={{position : 'absolute', left:'445px', top:'490px', backgroundColor:'#FFFAF0', fontSize:'16px', color:'black', height:'18px'}}>
                                            <Typography fontSize='16px'>Z3 low&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473'>{oven_zone.oven_z3_low}</Typography>
                                            <Typography fontSize='16px'>&nbsp;°C</Typography>
                                          </Button>

                                          {/* Brine chiller 1 cold */}
                                          <Button style={{position : 'absolute', left:'870px', top:'70px', backgroundColor:'#F5F5F5', fontSize:'12px', color:'black', height:'44px', flexDirection:'column'}} variant="contained">
                                            <Typography fontSize='16px'>Chiller cold&nbsp;&nbsp;</Typography>
                                            <Typography fontSize='18px' color='#058473' marginTop='-5px'>{prep_zone.chiller_cold_temp} &nbsp;°C</Typography>
                                          </Button>

                                          {/* Count pack */}
                                          <Button style={{position : 'absolute', left:'552px', top:'570px', backgroundColor:'#FFDAB9', fontSize:'16px', color:'black', height:'46px', flexDirection:'column' }} variant="contained">
                                            <Typography fontSize='18px' color='#058473'>{packing_zone.count_pack.toLocaleString()}</Typography>
                                            <Typography fontSize='16px' marginTop='-5px'>packs</Typography>
                                          </Button>
                                          {/* Count bask */}
                                          <Button style={{position : 'absolute', left:'750px', top:'578px', backgroundColor:'#FFDAB9', fontSize:'16px', color:'black', height:'46px', flexDirection:'column'}} variant="contained">
                                            <Typography fontSize='18px' color='#058473'>{packing_zone.count_basket.toLocaleString()}</Typography>
                                            <Typography fontSize='16px' marginTop='-5px'>baskets</Typography>
                                          </Button>
                                      </Box>

                                  </Box>

                              </Box>  

                            </Dialog>
                        </Box>
                         <Box justifyContent='center' marginTop='4px' >
                          
                          <img src ={img_Layout} alt="" width ="560px" height="432px"></img>
                          
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Chiller 1 cold</Typography>} arrow placement="left">
                              <CircleRoundedIcon style={{position : 'absolute', left:'91.7vw', top:'22vw', fontSize:'20px', color: chiller1cold_status.color}}>Chiller 1 cold</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Chiller 2</Typography>} arrow placement="left">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'90.4vw', top:'23.2vw', fontSize:'20px', color: chiller2_status.color}}>Chiller 2 </CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Sponge mixer</Typography>} arrow placement="right">
                              <CircleRoundedIcon style={{position : 'absolute', left:'61.5vw', top:'26.4vw', fontSize:'20px', color: spongemixer_status.color}}>Sponge mixer</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Semi AHU1</Typography>} arrow placement="right">
                              <CircleRoundedIcon style={{position : 'absolute', left:'58vw', top:'22.2vw', fontSize:'20px', color: semi_ahu1_status.color}}>Semi AHU1</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">AHU1</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'56vw', top:'23.9vw', fontSize:'20px', color: ahu1_status.color}}>AHU1</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dough mixer</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'57.2vw', top:'33.4vw', fontSize:'20px', color: doughmixer_status.color}}>Dough mixer</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Makeup system</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'55.8vw', top:'34.6vw', fontSize:'20px', color: makeup_system_status.color}}>Makeup system</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dough pump</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'59.5vw', top:'39vw', fontSize:'20px', color: doughpump_status.color}}>Dough pump</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Dust collector</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'67.2vw', top:'34.3vw', fontSize:'20px', color: dustcollector_status.color}}>Dust collector</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">AHU2</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'73.2vw', top:'33.6vw', fontSize:'20px', color: ahu2_status.color}}>AHU2</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Final proof</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'75.4vw', top:'32vw', fontSize:'20px', color: finalproof_status.color}}>Final proof</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Seeder</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'73.4vw', top:'38.9vw', fontSize:'20px', color: seeder_status.color}}>Seeder</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Oven</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'66vw', top:'47.4vw', fontSize:'20px', color: oven_status.color}}>Oven</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Depanner</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'81vw', top:'30.7vw', fontSize:'20px', color: depanner_status.color}}>Depanner</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Depanner washer</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'81.4vw', top:'24.9vw', fontSize:'20px', color: depannerwasher_status.color}}>Depanner washer</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Washer</Typography>} arrow placement="right">
                              <CircleRoundedIcon style={{position : 'absolute', left:'79.6vw', top:'22.2vw', fontSize:'20px', color: washer_status.color}}>Washer</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Turn over baking room</Typography>} arrow placement="right">
                              <CircleRoundedIcon style={{position : 'absolute', left:'74.3vw', top:'21.5vw', fontSize:'20px', color: turnOverBake_status.color}}>Turn over baking room</CircleRoundedIcon>
                            </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Stacker</Typography>} arrow placement="right">
                              <CircleRoundedIcon style={{position : 'absolute', left:'69vw', top:'21.5vw', fontSize:'20px', color: stacker_status.color}}>Stacker</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Unstacker</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'67.4vw', top:'22.8vw', fontSize:'20px', color: unstacker_status.color}}>Unstacker</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Turn over mixing room</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'65.6vw', top:'24vw', fontSize:'20px', color: turnOverMix_status.color}}>Turn over mixing room</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Spiral cooling</Typography>} arrow placement="left">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'90vw', top:'35.5vw', fontSize:'20px', color: spiralcooling_status.color}}>Spiral cooling</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Packing</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'79.8vw', top:'48.5vw', fontSize:'20px', color: packing_status.color}}>Packing</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Metal detector</Typography>} arrow placement="right">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'81.5vw', top:'50.6vw', fontSize:'20px', color: md_status.color}}>MD</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Basket unstack</Typography>} arrow placement="left">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'95.6vw', top:'48.5vw', fontSize:'20px', color: basketUnstack_status.color}}>Basket unstack</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Curt feeder</Typography>} arrow placement="left">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'90.6vw', top:'47.8vw', fontSize:'20px', color: curtfeeder_status.color}}>Curt feeder</CircleRoundedIcon>
                          </Tooltip>
                          <Tooltip title={<Typography color="inherit" fontSize="16px">Stack feeder</Typography>} arrow placement="left">    
                              <CircleRoundedIcon style={{position : 'absolute', left:'92.9vw', top:'48.8vw', fontSize:'20px', color: stackfeeder_status.color}}>Stack feeder 33</CircleRoundedIcon>
                          </Tooltip>
                        </Box>
                      </Box>
                      
                    </Item>
                </Grid>
            </Grid>
            
         </Box> 
    </div>
  );
};

export default Production;