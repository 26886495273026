export const apiURL = {
    OEE : 'https://server-side-mes-bakery.com/api/oee/4105/', //format date = YYYY-MM-DD
    FactoryDateData : 'https://server-side-mes-bakery.com/api/factory-date/4105/',
    UpdatePlan : 'https://server-side-mes-bakery.com/api/planning/insertion-init-firestore/',
    UpdatePlan_2 : 'https://server-side-mes-bakery.com/api/planning/updating_plan/',
    LOGIN_URL : 'https://server-side-mes-bakery.com/account/login/',
    TOKEN : 'https://server-side-mes-bakery.com/account/token/refresh/',
    REGISTER_URL : 'https://server-side-mes-bakery.com/account/signup/',
    USER_URL : 'https://server-side-mes-bakery.com/account/user/',
    RESET_PW :'https://server-side-mes-bakery.com/account/request-reset-email/',
    //SET_PW :'https://server-side-mes-bakery.com/account/request-reset/uidb64/token',
    SET_PW : 'https://server-side-mes-bakery.com/account/password-reset-complete',
    USER_EDIT : 'https://server-side-mes-bakery.com/account/user',
    REDIRECT_URL :'https://mes-bakery.cpfmes.com/reset-pw/',

} 